<template>
    <div class="layout">
        <headerVue />
        <div class="main">
            <mineVue />
        </div>
        <rightBtn ref="rightBtn"></rightBtn>
        <footerVue />
    </div>
</template>

<script>
import headerVue from '../components/header.vue';
import footerVue from '../components/footer.vue';
import rightBtn from '../components/rightBtn.vue';
import mineVue from './main.vue';
export default {
    components: {
        headerVue,
        footerVue,
        mineVue,
        rightBtn
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        // 隐藏右侧按钮
        if(screen.width < 770){
            this.$refs.rightBtn.handleHideBtn() 
        }
    },
    destroyed() {
        // 在组件销毁前移除事件监听器，避免内存泄漏  
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.currentScroll = window.pageYOffset //表示当前滚动的位置
            if (this.currentScroll > 100) { //当滚动的位置大于0时，显示右侧按钮
                this.$refs.rightBtn.showBtn()
            }else{
                this.$refs.rightBtn.hideBtn() 
            }

        }
    }
}
</script>

<style lang="scss" scoped></style>