import Vue from 'vue'

import router from './router'
import store from './store'

import ElementUI from 'element-ui';// 引入element-ui
import 'element-ui/lib/theme-chalk/index.css'; // 引入element-ui样式
import App from './App.vue'
import '@/assets/css/index.css' // 引入公共样式
import animated from 'animate.css' //引入animate.css
import VueParticles from 'vue-particles' //引入粒子插件
import 'lib-flexible/flexible' //引入lib-flexible 自适应屏幕大小 给html添加font-size属性
import VueAwesomeSwiper from 'vue-awesome-swiper' // 引入swiper
import 'swiper/css/swiper.css' // 引入swiper样式
import '../permission'
import Directives from "./directives"; //引入自定义指令



Vue.config.productionTip = false

Vue.use(VueAwesomeSwiper) // 使用swiper
Vue.use(ElementUI); // 使用element-ui
// Vue.use(animated) // 使用动画animate.css
Vue.use(VueParticles) //使用粒子插件
Vue.use(Directives); //使用directives 自定义指令
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
