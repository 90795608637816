<template>
  <div class="footer p75">
    <div class="content df">
      <div class="footer-left">
        <div class="comm df">
          <div class="comm-box df" v-for="(item, index) in navRouterList" :key="item.menuName">
            <div class="menu">{{ item.menuName }}</div>
            <div class="sub-menu df">
              <div class="sub-item" v-show="subItem.visible == 0" v-for="(subItem, index) in item.children"
                :key="subItem.menuName" @click="toPath(item, subItem)">
                <div>{{ subItem.menuName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-right">
        <!-- <p>营业执照：<span>{{ companyMessage.blicense }}</span></p> -->
        <p>地址：<span>{{ companyData.address }}</span></p>
        <p>邮箱：<span>{{ companyData.email }}</span></p>
        <p>电话：<span>{{ companyData.phone }}</span></p>
        <p>传真：<span>{{ companyData.fax }}</span></p>
        <div class="code">
          <p>订阅我们</p>
          <div class="firm">扫描二维码关注微信公众号，第一时间获得最新动态</div>
          <div class="code-pic df">
            <div class="item">
              <div class="pic">
                <div class="qr-scanner">
                  <div class="box">
                    <div class="line"></div>
                    <div class="angle"></div>
                  </div>
                </div>
                <img :src="baseurl + companyData.publicAccount" alt="">
              </div>
              <div class="pic-desc">微信公众号：中新图创</div>
            </div>
            <div class="item">
              <div class="pic">
                <div class="qr-scanner">
                  <div class="box">
                    <div class="line"></div>
                    <div class="angle"></div>
                  </div>
                </div>
                <img :src="baseurl + companyData.companyWeixin" alt="">
              </div>
              <div class="pic-desc">企业微信号：中新图创</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desc df">
      <div class="about">{{ companyData.companyName }}</div>
      <a class="record" href="https://beian.miit.gov.cn/">{{ companyData.icp }}</a>
    </div>
  </div>
</template>
<script>
import { selectCompanyInfo } from '@/api/company';
export default {
  name: "Footer",
  data() {
    return {
      baseurl: '',
      navRouterList: [], //导航路由列表
      siteMessage: {}, //
      companyData: {}, //公司信息
    }
  },
  created() {
    //获取导航路由列表
    this.$store.dispatch('generateRoutes').then(res => {
      let routes = JSON.parse(JSON.stringify(res))
      routes.forEach(item => {
        if (item.visible && item.menuName != '搜索页面' && item.menuName != '联系我们') {
          this.navRouterList.push(item)
        }
      });
    })
  },
  mounted() {
    this.baseurl = process.env.VUE_APP_BASE_URL
    this.getselectCompanyInfo()
  },
  methods: {
    //查询公司信息
    getselectCompanyInfo() {
      selectCompanyInfo().then(res => {
        this.companyData = res.data.data[0]
       
      })
    },
    toPath(item, subItem) {
      // 点击一级菜单
      if (item.menuId) {
        this.$store.commit('nav/SET_ROUTERID', item.menuId)
        sessionStorage.setItem('menuName', item.menuName)
      } else {
        sessionStorage.removeItem('menuName')
      }
      //点击二级菜单
      if (subItem.menuId) {
        this.$router.push(`${subItem.path}`)
        this.$store.commit('nav/SET_SUBROUTERID', subItem.menuId)

        if(subItem.menuName !='公司动态'){ //点击的不是公司动态 -- 存储子菜单名称
          sessionStorage.setItem('subMenuName', subItem.menuName)
        }else{//点击的是公司动态 -- 删除子菜单名称
          sessionStorage.removeItem('subMenuName')
        }

        if (subItem.menuId == 5) {
          this.$store.commit('nav/SET_LOCATIONID', 'synopsis')

        }
        if (subItem.menuId == 6) {
          this.$store.commit('nav/SET_LOCATIONID', 'develop')

        }
        if (subItem.menuId == 7) {
          this.$store.commit('nav/SET_LOCATIONID', 'aptitude')
        }
        if (subItem.menuId == 8) {
          this.$store.commit('nav/SET_LOCATIONID', 'technology')
        }
        if (subItem.menuId == 18) {
          this.$store.commit('nav/SET_LOCATIONID', 'serve')
        }
        if (subItem.menuId == 19) {
          this.$store.commit('nav/SET_LOCATIONID', 'contact')
        }
        if (subItem.menuId == 21) {
          this.$store.commit('nav/SET_LOCATIONID', 'message')
        }
      } else {
        sessionStorage.removeItem('subMenuName')
      }

      let menuName = sessionStorage.getItem('menuName') ? sessionStorage.getItem('menuName') : ''
      let subMenuName = sessionStorage.getItem('subMenuName') ? sessionStorage.getItem('subMenuName') : ''
      document.title = subMenuName ? `${subMenuName}_${menuName}_中新图创官网` : `${menuName}_中新图创官网`
    }
  }
};
</script>
<style scoped lang="scss">
$type: screen;
$proto: max-width;
$value: 780px;

.footer {
  background: #272727;
  padding-bottom: 20px;
  .footer-left {
    width: 55%;
    flex-wrap: wrap;

    .comm {
      margin-right: 100px;
      justify-content: space-between;

      .comm-box {
        flex-direction: column;
        color: #a3a6a7;

        .menu {
          text-align: left;
          color: #fff;
          font-size: 17px;
          padding-bottom: 15px;
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 30px;
            height: 2px;
            position: absolute;
            left: 0;
            bottom: 0;
            background-image: -webkit-linear-gradient(0deg, rgb(175, 30, 40) 0%, rgba(175, 30, 40, 0) 100%);
          }
        }

        .sub-menu {
          flex-direction: column;

          .sub-item {
            margin-top: 10px;
            text-align: left;
            cursor: pointer;

            &:hover {
              color: #fff;
            }
          }
        }
      }


    }

  }

  .footer-right {
    height: 100%;
    padding-left: 70px;
    border-left: 1px solid #777777;
    color: #777777;
    text-align: left;
    font-size: 14px;

    p {
      line-height: 30px;

      &:hover {
        color: #fff;
      }
    }

    .firm {
      font-size: 13px;
      margin: 10px 0;
    }

    .code {
      margin-top: 20px;

      p {
        color: #fff;
      }

      .firm {
        margin: 5px 0 20px 0;
      }

      .code-pic {
        .item{
          margin-right: 20px;
          .pic {
            position: relative;
            width: 100px;
            height: 100px;
            padding: 7px;
            border: 1px solid #525252;
            
            img {
              width: 100%;
              height: 100%;
            }
  
            .qr-scanner {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
              pointer-events: none;
  
              .box {
                height: 100%;
                width: 100%;
                position: relative;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                overflow: hidden;
  
                .line {
                  height: 100%;
                  width: 100%;
                  background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, #af1e28 211%);
                  border-bottom: 1px solid #af1e28;
                  transform: translateY(-100%);
                  animation: radar-beam 2s infinite;
                  animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
                  animation-delay: 1.4s;
                }
  
                @keyframes radar-beam {
                  0% {
                    transform: translateY(-100%);
                  }
  
                  100% {
                    transform: translateY(0);
                  }
                }
              }
            }
  
          }
          .pic-desc {
            margin-top: 5px;
            font-size: 13px;
          }
        }
      }

    }
  }

  .desc {
    border-top: 1px solid #777777;
    margin-top: 40px;
    padding-top: 20px;
    color: #777777;
    line-height: 15px;

    .about {
      border-right: 3px solid #777777;
      padding-right: 10px;
      margin-right: 10px;
    }

    .record {
      cursor: pointer;
      color: #777777;

      &:hover {
        color: #fff;
      }
    }

  }

  .footer-phone {
    display: none;
  }
}



@media #{$type} and ($proto: $value) {
  .footer {
    padding:20px !important;

    .footer-left {
      display: none;
    }

    .footer-right {
      padding-left: 0px !important;
      border: none;
      
      .code-pic{
        .item{
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .pic{
          width: 80px !important;
          height: 80px !important;
        }
      }
    }
  }
}
</style>
