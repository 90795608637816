<template>
    <div class="header" @mouseover="handleMouseOver" @mouseout="handleMouseOut">
        <div class="menu-wrapper df">
            <!-- 头部顶部 -->
            <div class="header-top">
                <img :src="baseurl + companyData.logo" alt="">
            </div>
            <el-menu :default-active="$route.path" active-text-color="#800000" class="el-menu-demo my-menu"
                mode="horizontal" :text-color='textColor' router>
                <!-- 没儿子 -->
                <template v-for="item in navRouters">
                    <el-menu-item :index="item.children[0].path" @click="toPath(item)"
                        v-if="item.hidden == false && item.children.length <= 2" :key="item.children[0].menuName">
                        <span slot="title">{{ item.children[0].menuName }}</span>
                    </el-menu-item>

                    <!-- 有儿子 -->
                    <el-submenu :index="item.menuName" v-if="item.hidden == false && item.children.length > 2"
                        :key="item.menuName">
                        <template slot="title">
                            <span>{{ item.menuName }}</span>
                        </template>
                        <el-menu-item ref="menuItem" class="menuItem" style="color: #333;"
                            v-for="subItem in item.children" :key="subItem.menuName" v-if="!subItem.hidden"
                            @click="toPath(item, subItem)" :index="subItem.path">{{
        subItem.menuName }}</el-menu-item>
                    </el-submenu>
                </template>
            </el-menu>
            <div class="icon df">
                <!-- 搜索 -->
                <div class="seacher">
                    <i class="el-icon-search" @click="drawer = true" type="primary"></i>
                </div>
                <!-- 手机端图标 -->
                <div class="phone-menu">
                    <i class="el-icon-menu" @click="isShow = !isShow"></i>
                </div>
            </div>
        </div>

        <!-- 搜索抽屉 -->
        <el-drawer :append-to-body="true" :modal-append-to-body="false" :visible.sync="drawer" :direction="direction"
            :before-close="handleClose">
            <div class="content">
                <el-input placeholder="请输入你想查询的内容" v-model="seacherText">
                    <i slot="suffix" class="el-input__icon el-icon-search" @click="toEacher(seacherText)"></i>
                </el-input>
            </div>
        </el-drawer>

        <!-- 手机端导航 -->
        <el-collapse v-show="isShow" v-model="activeNames" accordion>
            <template v-for="item in navRouters">
                <div class="collapse-title-only" v-if="item.hidden == false && item.children.length <= 2"
                    :key="item.children[0].menuName" @click="toMenu(item)">
                    {{ item.children[0].menuName }}
                </div>
                <el-collapse-item :name="item.menuName" v-if="item.hidden == false && item.children.length > 2"
                    :key="item.menuName" :title="item.menuName">
                    <div class="collapse-title" v-for="subItem in item.children" v-if="!subItem.hidden"
                        :key="subItem.menuName" @click="toMenu(item, subItem)">{{ subItem.menuName }}</div>
                </el-collapse-item>
            </template>

        </el-collapse>

    </div>
</template>

<script>
import { selectCompanyInfo } from '@/api/company';
export default {
    data() {
        return {
            baseurl: '',
            isShow: false,  //手机端导航是否显示
            activeNames: [], //手机端折叠板是否展开
            textColor: '#fff', // 导航栏文字颜色
            direction: 'ttb', //抽屉方向 上方打开
            drawer: false, // 搜索抽屉是否显示
            seacherText: '', //搜索框内容
            navRouters: [], // 导航路由
            companyData: {} //公司信息
        }
    },
    watch: {
        $route(to, from) {
            if (to.path != '/home') {
                this.whiteNav()
            }
        },
    },
    created() {
        this.$store.dispatch('generateRoutes').then(res => {
            this.selectRouter(res)
            this.navRouters = res

            this.$nextTick(() => {

                if (this.$route.path != '/home') {
                    this.whiteNav()
                } else {
                    this.$refs.menuItem.forEach((item) => {
                        item.$el.addEventListener('mouseover', () => {
                            this.handleItemMouseover();
                        })
                    })
                }
            })
        })
    },

    mounted() {
        this.baseurl = process.env.VUE_APP_BASE_URL
        this.getselectCompanyInfo()
        // 添加滚动事件监听器  
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        // 在组件销毁前移除事件监听器，避免内存泄漏  
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        //查询公司信息
        getselectCompanyInfo() {
            selectCompanyInfo().then(res => {
                this.companyData = res.data.data[0]
            })
        },
        //筛选路由，将visible为0的增加hiddent属性
        selectRouter(router) {
            router.forEach((item) => {
                if (item.visible) {
                    if (item.visible == '0') {
                        item.hidden = false
                    } else {
                        item.hidden = true
                    }
                }
                if (item.children && item.children.length > 0) {
                    this.selectRouter(item.children)
                }
            })

        },
        handleScroll() {
            if (this.$route.path == '/home') {
                // 获取滚动条的位置  
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                // 检查滚动条位置是否大于0  
                if (scrollTop > 0) {
                    this.whiteNav()
                } else {
                    // 如果滚动回顶部，移除样式  
                    this.originalNav()
                }
            }
        },
        // 移入事件
        handleMouseOver(event) {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (this.$route.path == '/home' && scrollTop <= 0) {
                this.whiteNav()
            }
        },
        // 移出事件
        handleMouseOut() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (this.$route.path == '/home' && scrollTop <= 0) {
                this.originalNav()
            }
        },
        // 二级菜单移入事件
        handleItemMouseover() {
            this.whiteNav()
        },
        // 改变导航栏样式 --白色背景
        whiteNav() {
            let myMenu = document.querySelector('.header');
            let search = document.querySelector('.el-icon-search');
            search.style.color = '#000';
            myMenu.classList.add('scrolled');
            this.textColor = '#333'
        },
        // 改变导航栏样式 --原色(透明色)背景
        originalNav() {
            let myMenu = document.querySelector('.header');
            let search = document.querySelector('.el-icon-search');
            myMenu.classList.remove('scrolled');
            search.style.color = '#fff';
            this.textColor = '#fff'
        },
        // 关闭搜索抽屉
        handleClose(done) {
            this.drawer = false;
            this.seacherText = ''
        },
        // 去搜索页面
        toEacher(data) {
            this.$store.commit('nav/SET_SEACHER', data)
            this.$router.push({ path: '/seacher' })
            this.handleClose()
        },
        toPath(item, subItem) {
            //一级菜单
            if (item) {//点击一级菜单，则存储一级菜单的id以及名称
                this.$store.commit('nav/SET_ROUTERID', item.menuId)
                sessionStorage.setItem('menuName', item.menuName)
            }
            //二级菜单
            if (subItem) { //点击二级菜单，则存储二级菜单的id以及名称
                this.$store.commit('nav/SET_SUBROUTERID', subItem.menuId)
                sessionStorage.setItem('subMenuName', subItem.menuName)
                if (subItem.menuId == 5) {
                    this.$store.commit('nav/SET_LOCATIONID', 'synopsis')
                }
                if (subItem.menuId == 6) {
                    this.$store.commit('nav/SET_LOCATIONID', 'develop')
                }
                if (subItem.menuId == 7) {
                    this.$store.commit('nav/SET_LOCATIONID', 'aptitude')
                }
                if (subItem.menuId == 8) {
                    this.$store.commit('nav/SET_LOCATIONID', 'technology')
                }
                if (subItem.menuId == 18) {
                    this.$store.commit('nav/SET_LOCATIONID', 'serve')
                }
                if (subItem.menuId == 19) {
                    this.$store.commit('nav/SET_LOCATIONID', 'contact')
                }
                if (subItem.menuId == 21) {
                    this.$store.commit('nav/SET_LOCATIONID', 'message')
                }
            } else { //没点击二级菜单，则清空二级菜单的名称
                sessionStorage.removeItem('subMenuName')
            }
            let menuName = sessionStorage.getItem('menuName') ? sessionStorage.getItem('menuName') : ''
            let subMenuName = sessionStorage.getItem('subMenuName') ? sessionStorage.getItem('subMenuName') : ''
            document.title = subMenuName ? `${subMenuName}_${menuName}_中新图创官网` : `${menuName}_中新图创官网`
        },
        // 手机端菜单跳转
        toMenu(item, subItem) {
            this.isShow = false
            this.activeNames = []

            if (item.redirect == '/home') {
                this.$router.push(item.children[0].path)
            } else {
                if (item && subItem == undefined) {
                    this.$store.commit('nav/SET_ROUTERID', item.menuId)
                    this.$router.push(item.children[0].path)
                } else {
                    this.$store.commit('nav/SET_ROUTERID', item.menuId)
                    this.$store.commit('nav/SET_SUBROUTERID', subItem.menuId)
                    this.$router.push(subItem.path)

                    if (subItem.menuId == 5) {
                        this.$store.commit('nav/SET_LOCATIONID', 'synopsis')
                    }
                    if (subItem.menuId == 6) {
                        this.$store.commit('nav/SET_LOCATIONID', 'develop')
                    }
                    if (subItem.menuId == 7) {
                        this.$store.commit('nav/SET_LOCATIONID', 'aptitude')
                    }
                    if (subItem.menuId == 8) {
                        this.$store.commit('nav/SET_LOCATIONID', 'technology')
                    }
                    if (subItem.menuId == 18) {
                        this.$store.commit('nav/SET_LOCATIONID', 'serve')
                    }
                    if (subItem.menuId == 19) {
                        this.$store.commit('nav/SET_LOCATIONID', 'contact')
                    }
                    if (subItem.menuId == 21) {
                        this.$store.commit('nav/SET_LOCATIONID', 'message')
                    }
                }
            }


        },

    },


}
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    background-color: transparent;
    width: 100%;
    z-index: 1000;

    .menu-wrapper {
        width: 100%;
        align-items: center;

        .header-top {
            padding-left: 5%;
            margin-right: 260px;

            img {
                height: 40px;
            }
        }

        .icon {
            i {
                color: #fff;
                font-size: 16px;
                cursor: pointer;
            }
        }

        ::v-deep .el-menu-demo {

            // 修改有儿子标题的内外边距
            .el-submenu__title {
                padding: 0;
                margin: 0 30px;
              
                // 修改有儿子标题的字体颜色
                &:hover {
                    color: #800000;
                    background-color: transparent !important;
                    border-bottom: 3px solid #800000 !important;
                }


            }

            .el-menu-item:hover {
                /* 透明背景 */
                background-color: transparent !important;
                border-bottom: 3px solid #800000 !important;
            }

            .el-menu-item {
                margin-right: 30px;
             
                &.is-active {
                    background-color: transparent !important;
                }
                // 修改没儿子菜单字体大小·
                span{
                    font-weight: bolder;
                    font-size: 16px;
                }
            }

            // 隐藏 el-submenu 下的箭头
            .el-submenu__icon-arrow {
                display: none;
            }
        }

        &:hover {
            i {
                color: #000;
            }
        }
    }
}

::v-deep .el-drawer.ttb {
    background: linear-gradient(to bottom, rgb(175, 30, 40), 70%, transparent);

    .content {
        padding: 0 20%;

        .el-input {
            text-align: center;
            
            .el-input__inner {
                font-size: 20px;
                height: 50px;
                border-color: #999;
                background-color: transparent;
                color: #fff;
            }
        }
        .el-input__icon{
            padding-top: 2px;
            cursor: pointer;
            font-size: 18px;
        }
    }
}

.scrolled {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

.phone-menu {
    display: none;
}

@media screen and (max-width: 770px) {
    .menu-wrapper {
        justify-content: space-between;
        background-color: #fff;
    }

    ::v-deep .el-menu-demo {
        display: none;

    }

    .icon {
        i {
            color: #000 !important;
            display: block !important;
            padding-right: 20px;
            font-size: 20px;
        }
    }


    .header-top {
        margin-right: 0 !important;

        img {
            height: 20px !important;
            padding: 10px 10px 10px 0;
        }
    }

    .phone-menu {
        display: block !important;
    }

    ::v-deep .el-collapse {

        .collapse-title-only {
            line-height: 40px;
            border-bottom: 1px solid #EBEEF5;
            font-size: 13px;
        }
        
        .el-collapse-item__header {
            height: 40px;
            font-size: 13px;
        }

        .el-collapse-item__content {
            padding-bottom: 10px;
        }
    }

    ::v-deep .el-input {
        .el-input__inner {
            height: 40px !important;
            font-size: 14px !important;
        }
        .el-input__icon{
            padding-top: 0 !important;
        }
    }
}
</style>
<style lang="scss">
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
    min-width: 120px !important;
}

/* 修改 el-submenu 的标题样式，使文字居中 */
.el-submenu .el-submenu__title {
    display: flex;
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    // 修改有儿子菜单字体大小·
    span{
        font-weight: bolder;
        font-size: 16px;
    }
}

.el-menu-item {
    &:hover {
        color: #800000 !important;
    }
}

/* 修改 el-menu-item 选中时的底部横线颜色*/
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
    border-bottom: 3px solid #800000 !important;
}

/* 修改 el-menu-item 的样式，使文字居中 */
.el-menu-item {
    display: flex;
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
}
</style>