import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
Vue.use(VueRouter)

//隐藏原地跳转错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
 return originalPush.call(this, location).catch(err => err)
}

export const constantRoutes = [
  // {
  //   path: '/redirect',
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: '/redirect/:path(.*)',
  //       component: () => import('@/views/redirect')
  //     }
  //   ]
  // },
   // 首页重定向
   {
    path: '/',
    hidden:true,//不在导航列表中显示
    redirect: '/home'
  },
  // 首页
  {
    path: '',
    component: Layout,
    hidden: false,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import("@/views/home/index.vue"),
        menuName:'首页'
      }
    ]
  },
 
  {
    path: '/404',
    hidden: true,
    meta: { path: '/404', title: '404' },
    component: () => import("@/views/404/error404.vue")
  },
]
const router = new VueRouter({
  mode: 'history',
  routes:constantRoutes
})

export default router


router.beforeEach((to, from, next) => {
  if (to.path === '/home' || to.path.includes('industry') || to.path.includes('product') || to.path =='/dynamic' || to.path =='/contact' ) {
    document.documentElement.scrollTop = 0
    next()
  } else {
    next()
  }
})
