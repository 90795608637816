//公司信息模块

//引入接口函数
import request from '@/utils/request'


//获取公司信息
export const selectCompanyInfo= query=>{
    return request({
        method:'get',
        url:'/zxtc/companyInfo/selectCompanyInfo',
        query,
    })
}