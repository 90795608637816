<template>
    <div class="right-btn">
        <el-popover placement="left" trigger="hover" popper-class="monitor-yt-popover">
            <img :src="baseurl + companyData.companyWeixin" alt="">
            <i slot="reference" class="el-icon-s-comment"></i>
        </el-popover>
        <el-popover placement="left" trigger="hover" popper-class="monitor-yt-popover">
            <p>{{ companyData.phone }}</p>
            <i slot="reference" class="el-icon-phone"></i>
        </el-popover>
        <!-- <el-popover placement="left" trigger="hover" popper-class="monitor-yt-popover">

            <i slot="reference" class="el-icon-s-custom"></i>
        </el-popover> -->
        <el-popover placement="left" trigger="hover" popper-class="monitor-yt-popover">
            <p>{{ companyData.email }}</p>
            <i slot="reference" class="el-icon-s-promotion"></i>
        </el-popover>
        <div class="to-top" @click="smoothScrollToTop">
            <i class="el-icon-upload2"></i>
        </div>
    </div>
</template>

<script>
import { selectCompanyInfo } from '@/api/company';
export default {
    data() {
        return {
            baseurl: '',
            companyData: {}, //公司信息
        }
    },
    mounted() {
        this.baseurl = process.env.VUE_APP_BASE_URL
        this.getselectCompanyInfo()
    },
    methods: {
        //查询公司信息
        getselectCompanyInfo() {
            selectCompanyInfo().then(res => {
                this.companyData = res.data.data[0]
            })
        },
        showBtn() {
            document.querySelector('.right-btn').style.display = 'block';
            document.querySelector('.right-btn').style.animation = 'rightBtnFade 1s ease-in-out forwards';
        },
        hideBtn() {
            document.querySelector('.right-btn').style.display = 'none';
            document.querySelector('.right-btn').style.animation = '0s';
        },
        handleHideBtn() {
            document.querySelector('.right-btn').style.opacity = 0;
        },
        smoothScrollToTop() {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(this.smoothScrollToTop);
                // 这里的步长决定了滚动速度，可以根据需要调整  
                window.scrollTo(0, c - c / 30);
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.right-btn {
    position: fixed;
    bottom: 50px;
    right: 20px;
    background: #fff;
    z-index: 555;
    border-radius: 30px;
    padding: 20px 10px;
    display: none;

    ::v-deep span {
        display: block;
        margin-bottom: 10px;
    }

    ::v-deep .el-popover__reference-wrapper {
        cursor: pointer;

        i {
            font-size: 25px;
            color: bisque;
        }

        :hover {
            color: #af1e28;
        }
    }

    .to-top {
        cursor: pointer;

        i {
            color: bisque;
            font-size: 25px;

        }

        :hover {
            color: #af1e28;
        }

    }
}
</style>
<style lang="scss">
.el-popover.monitor-yt-popover {
    background-color: #af1e28;
    border-color: #af1e28;

    p {
        text-align: center;
        font-size: 16px;
        color: #fff;
    }

    img {
        margin: 0 auto;
        width: 100px;
        height: 100px;
    }
}

.monitor-yt-popover .el-popover__title {
    color: #af1e28;
}

.monitor-yt-popover .popper__arrow::after {
    border-left-color: #af1e28 !important;
}
</style>