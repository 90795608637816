import router, { constantRoutes } from '@/router/index'

import { selectMenuTreeBase } from '@/api/nav';
import Layout from '@/layout/index.vue';
import config from '@/config';
//引入进度条
import NProgress from 'nprogress'
NProgress.configure({ showSpinner: false }) // NProgress Configuration
import 'nprogress/nprogress.css'



const whiteList = ['/home']

var getRouter

router.beforeEach(async (to, from, next) => {
    // document.title = '西安中新图创科技有限公司官网'

    //开启进度条
    NProgress.start()

    if (!getRouter) {
        if (to.path == '/home') {
            next()
        }
        if (!getObjArr('router')) { // getObjArr是封装的获取localStorage的方法
            await selectMenuTreeBase().then(res => {
                getRouter = res.data.data // 后台拿到路由
                saveObjArr('router', getRouter)  // 存储路由到localStorage的封装方法
                routerGo(to, next) //执行路由跳转方法
            })

        } else {//从localStorage拿到了路由
            getRouter = getObjArr('router')
            routerGo(to, next)
        }
    } else {
        next()
        NProgress.done() //关闭进度条
    }
    
})

//路由后置守卫
router.afterEach(() => {
    NProgress.done() //关闭进度条
})

// 导航到一个新的路由方法封装
function routerGo(to, next) {
    getRouter = filterAsyncRouter(getRouter) // 过滤路由
    getRouter.push({ path: '*', redirect: '/404', hidden: true })
    router.options.routes = constantRoutes.concat(getRouter); //必须在addroutes前，使用router.options.routes=XXXXX的方法手动添加
    getRouter.forEach((item) => {
        router.addRoute(item) // 动态添加路由
    })
    config.antRouter = constantRoutes.concat(getRouter) //将路由数据传递给全局变量，做侧边栏菜单渲染工作
    next({ ...to, replace: true })
}

// 存储 localStorage 数组对象的方法
function saveObjArr(name, data) { // localStorage 存储数组对象的方法
    sessionStorage.setItem(name, JSON.stringify(data))
}

// 获取 localStorage数组对象的方法封装
function getObjArr(name) { // localStorage 获取数组对象的方法
    return JSON.parse(window.sessionStorage.getItem(name));
}


function loadView(view) {
    if (process.env.NODE_ENV === 'development') {
        return (resolve) => require([`@/views/${view}`], resolve)
    } else {
        // 使用 import 实现生产环境的路由懒加载
        return () => import(`@/views/${view}`)
    }

}

//过滤路由方法封装
function filterAsyncRouter(asyncRouterMap) { //遍历后台传来的路由字符串，转换为组件对象
    const accessedRouters = asyncRouterMap.filter(route => {
        if (route.component) {
            if (route.component === 'Layout') { // Layout组件特殊处理
                route.component = Layout
                route.name=route.menuName
              
            } else {
                route.component = loadView(route.component) // 动态加载路由组件
                route.name=route.menuName
            }

            if (route.children && route.children.length) {
                route.children = filterAsyncRouter(route.children)
            }
        }
        return true
    })
    return accessedRouters
}