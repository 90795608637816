import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import getters from './getters'
import nav from './modules/nav';
import permission from './modules/permission';
export default new Vuex.Store({
  getters,
  modules: {
    nav,
    permission
  }
})
