import router, { constantRoutes } from '@/router/index'
import { selectMenuTreeBase } from '@/api/nav';
import Layout from '@/layout/index.vue';

const permission = {
    state: {
        routes: ['1'],
    },
    mutations: {
        set_routes(state, routes) {
            state.routes = constantRoutes.concat(routes)
        }
    },
    actions: {
        generateRoutes({ commit }, data) {
            return new Promise(resolve => {
                selectMenuTreeBase().then(res => {
                    const sdata = JSON.parse(JSON.stringify(res.data.data))
                    const rewriteRoutes = filterAsyncRouter(sdata)
                    rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })
                   
                    commit('set_routes',rewriteRoutes)
                    resolve(constantRoutes.concat(rewriteRoutes))
                })
            })
        }

    }
}

function loadView(view) {
    if (process.env.NODE_ENV === 'development') {
        return (resolve) => require([`@/views/${view}`], resolve)
    } else {
        // 使用 import 实现生产环境的路由懒加载
        return () => import(`@/views/${view}`)
    }

}

//过滤路由方法封装
function filterAsyncRouter(asyncRouterMap) { //遍历后台传来的路由字符串，转换为组件对象
    const accessedRouters = asyncRouterMap.filter(route => {
        if (route.component) {
            if (route.component === 'Layout') { // Layout组件特殊处理
                route.component = Layout
            } else {
                route.component = loadView(route.component) // 动态加载路由组件
            }

            if (route.children && route.children.length) {
                route.children = filterAsyncRouter(route.children)
            }
        }
        return true
    })
    return accessedRouters
}

export default permission