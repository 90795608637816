//菜单模块

//引入接口函数
import request from '@/utils/request'


//查询菜单树
export const selectMenuTreeBase= query=>{
    return request({
        method:'get',
        url:   `/zxtc/menu/selectMenuTreeBase`,
        query,
    })
}

//查询一级菜单
export const selectMenuId= menuId=>{
    return request({
        method:'get',
        url:   `/zxtc/menu/selectMenuTreeByMenuId/${menuId}`,
    })
}

//查询二级菜单
export const selectMenuByMenuId= menuId=>{
    return request({
        method:'get',
        url:   `/zxtc/menu/selectMenuByMenuId/${menuId}`,
    })
}