import title from './title';

const directives = {
    title,
}


export default {
    install(Vue) {
      Object.keys(directives).forEach((item) => {
        Vue.directive(item, directives[item]);
      });
    },
  };