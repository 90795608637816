
let menuName=sessionStorage.getItem('menuName')
let subMenuName=sessionStorage.getItem('subMenuName')

export default {
    /**
     * @description 配置显示在浏览器标签的title
     */
    
    baseUrl:'http://192.168.1.15:8011',
    antRouter:'',
    pageTitle:subMenuName?`${subMenuName}-${menuName}-'中新图创`:`${menuName}-中新图创`,
}