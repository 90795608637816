const state = {
   routerId:JSON.parse(sessionStorage.getItem('routerId'))|| '',
   subRouterId:JSON.parse(sessionStorage.getItem('subRouterId')) || '',
   seacherText:JSON.parse(sessionStorage.getItem('seacherText')) || '',
   locationId:sessionStorage.getItem('locationId')?JSON.parse(sessionStorage.getItem('locationId')):'',
}

const mutations = {
  
   SET_ROUTERID(state, routerId) {
      state.routerId = routerId
      if(routerId){
         sessionStorage.setItem('routerId', JSON.stringify(routerId))
      }
   },
   SET_SUBROUTERID(state, subRouterId) {
      state.subRouterId = subRouterId
      sessionStorage.setItem('subRouterId', JSON.stringify(subRouterId))
   },
   SET_SEACHER(state, text){
      state.seacherText = text
      sessionStorage.setItem('seacherText', JSON.stringify(text))
   },
   SET_LOCATIONID(state, locationId) {
      state.locationId = locationId
      sessionStorage.setItem('locationId', JSON.stringify(locationId))
   },

}



export default {
    //命名空间
    namespaced: true,
    state,
    mutations
}